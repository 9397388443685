<template>
  <v-container fluid fill-height>
    <v-overlay :value="loadingGroup">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-sheet width="1310px" color="transparent mx-auto">
      <div class="mb-1 text-right">
        <GroupAppliedHint />
      </div>
      <v-row class="group-container">
        <v-col cols="12" sm="4" md="3" class="group">
          <v-card
            ripple
            class="
              group-card
              newgroup
              pa-2
              d-flex
              flex-column
              align-center
              justify-center
              rounded-lg
            "
            outlined
            width="100%"
            @click="editGroup(null)"
          >
            <v-icon color="secondary" x-large> mdi-plus-thick</v-icon>
            <div class="ems-blue-grey-2--text">新增群組</div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="3"
          v-for="(item, groupIndex) in groups"
          :key="item.id"
          :class="['group', { 'is-expanded': pool.id === item.id }]"
        >
          <v-sheet
            @click="showGroup(item)"
            class="py-2 px-3 group-card frosted-glass rounded-lg"
            width="100%"
          >
            <v-card-title
              class="d-flex justify-space-between align-center px-1 pt-1 pb-1"
            >
              <div class="d-flex align-center">
                <span class="font-weight-bold accent--text">
                  {{ item.name }}
                </span>
              </div>
              <div>
                <v-btn
                  v-if="
                    !item.isSetToSchedule &&
                    !item.isSetToOffloading &&
                    !item.isSetToOpenAdr
                  "
                  x-small
                  class="ml-2"
                  icon
                  color="ems-blue-grey"
                  @click="deleteGroup(item, groupIndex)"
                  ><v-icon> mdi-trash-can-outline </v-icon></v-btn
                >
              </div>
            </v-card-title>
            <v-card-text class="accent--text px-0">
              <div class="mb-1">
                <GroupApply :group="item" />
              </div>
              <!-- countMap -->
              <div
                class="body-1 d-flex align-center"
                v-if="item.type === 'METER'"
              >
                <v-img
                  max-height="32"
                  max-width="32"
                  src="@/assets/image/icon_Meter.svg"
                ></v-img>
                數量：{{ item.countMap.CLASSMETER || 0 }}
              </div>
              <div
                class="body-1 d-flex align-center"
                v-if="item.type === 'IR_AIR_CON'"
              >
                <v-img
                  class="mx-1"
                  max-height="32"
                  max-width="24"
                  contain
                  src="@/assets/image/acControl/ir.png"
                ></v-img>
                數量：{{ item.countMap.IR_AIRCONDITIONER || 0 }}
              </div>
              <div
                class="body-1 d-flex align-center"
                v-else-if="item.type === 'AIR_CON'"
              >
                <v-img
                  max-height="32"
                  max-width="32"
                  src="@/assets/image/icon_AC.svg"
                ></v-img>
                數量：{{ item.countMap.AIRCONDITIONER || 0 }}
              </div>
              <div
                class="body-1 d-flex align-center"
                v-else-if="item.type === 'CARD_READER'"
              >
                既設冷氣
              </div>
              <div class="body-1 d-flex mt-1" v-if="item.type === 'AIR_CON'">
                <v-sheet
                  width="32"
                  height="32"
                  class="text-center"
                  color="transparent"
                >
                  <v-icon
                    x-small
                    :color="
                      ['PARTIALLY_ON', 'ON'].includes(
                        item.statusMap.AIRCONDITIONER
                      )
                        ? 'success'
                        : 'error'
                    "
                  >
                    mdi-checkbox-blank-circle</v-icon
                  >
                </v-sheet>
                狀態：冷氣{{ state[item.statusMap.AIRCONDITIONER] }}
              </div>
              <div
                class="body-1 d-flex mt-1"
                v-if="item.type === 'CARD_READER'"
              >
                <v-sheet
                  width="32"
                  height="32"
                  class="text-center"
                  color="transparent"
                >
                  <v-icon
                    x-small
                    :color="
                      ['PARTIALLY_ON', 'ON'].includes(item.statusMap.CARDREADER)
                        ? 'success'
                        : 'error'
                    "
                  >
                    mdi-checkbox-blank-circle</v-icon
                  >
                </v-sheet>
                狀態：電驛{{ cardReaderState[item.statusMap.CARDREADER] }}
              </div>
            </v-card-text>
          </v-sheet>
          <div class="connectExpand"></div>
          <div
            class="group-card--detail accent--text"
            :class="removeBorderRadius(groupIndex)"
          >
            <div class="body-1 mb-3 d-flex justify-space-between">
              <v-btn
                v-if="
                  !item.isSetToSchedule &&
                  !item.isSetToOffloading &&
                  !item.isSetToOpenAdr
                "
                elevation="0"
                @click="editGroup(item)"
                color="primary"
                large
              >
                編輯群組</v-btn
              >
              <span
                v-if="
                  item.isSetToSchedule ||
                  item.isSetToOffloading ||
                  item.isSetToOpenAdr
                "
                class="caption grey--text"
                >已使用於超約卸載或排程或ADR方案，無法修改。</span
              >
              <v-btn icon color="ems-blue-grey" @click="showGroup(undefined)">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </div>
            <v-sheet
              width="100%"
              height="calc(100% - 60px)"
              class="d-flex justify-center"
              :class="{ 'align-center': pool.loading }"
              color="transparent"
              style="overflow-y: auto"
            >
              <v-progress-circular
                v-if="pool.loading"
                indeterminate
                size="64"
                color="ems-bluegrey"
              />
              <v-data-table
                v-else
                :headers="headers"
                :items="pool.deviceList"
                :items-per-page="5"
                class="groupDatatable elevation-0 mb-3"
                loading-text="Loading..."
                no-data-text="查無資料"
                :footer-props="{
                  'items-per-page-text': '每頁'
                }"
              ></v-data-table>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <v-navigation-drawer
      class="frosted-glass-25"
      fixed
      temporary
      right
      color="rgba(256, 256, 256, 0.2)"
      overlay-color="rgba(256, 256, 256, 0.2)"
      width="70%"
      v-model="menu"
    >
      <editGroupCard v-model="pool" @save="saveGroup($event)" />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Group from '@/api/ems/Group'
import editGroupCard from '@/views/school/group/editGroupCard'
import GroupAppliedHint from '@/views/school/groupApplied/Hint'
import GroupApply from '@/views/school/groupApplied/Apply'
import Swal from 'sweetalert2'

const InitGroup = () => ({
  id: '',
  name: '',
  type: '',
  deviceList: [],
  loading: false
})
export default {
  components: {
    editGroupCard,
    GroupAppliedHint,
    GroupApply
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loadingGroup: true,
    menu: false,
    state: {
      OFF: '關閉',
      ON: '運作中',
      PARTIALLY_ON: '部分運作中'
    },
    cardReaderState: {
      OFF: '關閉',
      ON: '部分運作中',
      PARTIALLY_ON: '部分運作中'
    },
    groups: [],
    devices: [],
    pool: InitGroup(),
    headers: [
      {
        text: '大樓',
        value: 'building.name',
        class: 'subtitle-1 font-weight-bold accent--text'
      },
      {
        text: '樓層',
        value: 'floor.name',
        class: 'subtitle-1 font-weight-bold accent--text'
      },
      {
        text: '教室',
        value: 'classroom.name',
        class: 'subtitle-1 font-weight-bold accent--text'
      },
      {
        text: '冷氣編號',
        value: 'name',
        class: 'subtitle-1 font-weight-bold accent--text'
      }
    ],
    addGroupLoading: false
  }),
  computed: {
    ...mapGetters({
      buildings: 'building/buildings',
      myGroups: 'groups/groups',
      types: 'groups/typeOptions'
    })
  },
  watch: {
    myGroups(val) {
      this.groups = val
    }
  },
  async mounted() {
    await this.getGroups(this.tagId)
    this.groups = this.myGroups
    this.loadingGroup = false
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      closeMsg: 'snackbar/close',
      getGroups: 'groups/getGroups'
    }),
    async showGroup(item) {
      if (!item || item.id === this.pool.id) {
        this.pool = InitGroup()
        return
      }
      this.setPool(item.id)
    },
    async setPool(groupId) {
      try {
        this.pool.id = groupId
        this.pool.loading = true
        const { data } = await Group.getDetail(groupId)
        this.pool = { ...this.pool, ...data }
        this.pool.type = this.getGroupTypeOptions(data.type)
      } catch (error) {
        console.error(error)
      } finally {
        this.pool.loading = false
      }
    },
    editGroup(item) {
      if (item === null) {
        this.pool = InitGroup()
      }
      this.menu = true
    },
    async saveGroup(params) {
      try {
        if (!params.id) {
          await Group.create(params)
        } else {
          await Group.patch(params.id, params)
        }
        await this.getGroups(this.tagId)
        if (params.id) this.setPool(params.id)
      } catch (error) {
        const msg = error.response.data.message || '發生錯誤，請稍後再試'
        this.showMsg({
          message: `${error.response.status + ' ' || ''} ${msg}`,
          color: 'error',
          closable: true,
          time: 4000
        })
      }
      this.menu = false
    },
    async deleteGroup(item, index) {
      Swal.fire({
        title: '確定要刪除此群組?',
        text: item.name,
        showCancelButton: true,
        cancelButtonText: '取消',
        confirmButtonText: '確定',
        confirmButtonColor: '#3085d6'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingGroup = true
          try {
            await Group.delete(item.id)
            this.groups.splice(index, 1)
          } catch (error) {
            const msg =
              error.response.data.errorMessage || '發生錯誤，請稍後再試'
            this.showMsg({
              message: `${error.response.status + ' ' || ''} ${msg}`,
              color: 'error',
              closable: true,
              time: 4000
            })
          } finally {
            await this.getGroups(this.tagId)
            this.groups = this.myGroups
            this.loadingGroup = false
          }
        }
      })
    },
    removeBorderRadius(index) {
      const serialInLine = index + 2
      let classes = ''
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          classes = 'removeTopLeftRadius removeRightTopRadius'
          break
        case 'sm':
          if (serialInLine % 3 === 0) classes = 'removeRightTopRadius'
          if (serialInLine % 3 === 1) classes = 'removeTopLeftRadius'
          break
        default:
          if (serialInLine % 4 === 0) classes = 'removeRightTopRadius'
          if (serialInLine % 4 === 1) classes = 'removeTopLeftRadius'
          break
      }
      return classes
    },
    getGroupTypeOptions(type) {
      switch (type) {
        case 'METER':
          return this.types[0]
        case 'AIR_CON':
          return this.types[1]
        case 'IR_AIR_CON':
          return this.types[2]
        case 'CARD_READER':
          return this.types[3]
        default:
          return 'NULL'
      }
    }
  }
}
</script>

<style lang="scss">
$expandColor: rgb(241, 253, 251);

.group-container {
  width: 100%;
  position: relative;
  // min-height: calc(100vh - 64px - 48px - 100px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: 3s;
  // max-width: 1310px;
  margin: 0 auto;
  overflow: hidden;
  .group {
    width: 220px;
    cursor: pointer;
    &.is-expanded {
      min-height: 515px;
      .group-card {
        border: 2px solid var(--v-primary-base) !important;
        background: rgb(241, 253, 251) !important;
      }
      // .tri {
      //   width: 0;
      //   height: 0;
      //   border-style: solid;
      //   border-width: 0 15px 20px 15px;
      //   border-color: transparent transparent #ffffff transparent;
      //   position: relative;
      //   margin-top: 0.25rem;
      // }
      .connectExpand {
        border-left: 2px solid var(--v-primary-base) !important;
        border-right: 2px solid var(--v-primary-base) !important;
        width: 100%;
        height: 15px;
        margin: 0;
        position: relative;
        background: $expandColor;
        &::before {
          content: '';
          height: 8px;
          width: calc(100% + 4px);
          position: absolute;
          top: -8px;
          left: -2px;
          background: $expandColor;
          border-left: 2px solid var(--v-primary-base) !important;
          border-right: 2px solid var(--v-primary-base) !important;
        }
        &::after {
          content: '';
          height: 2px;
          width: calc(100% + 0.6px);
          position: absolute;
          background: $expandColor;
          bottom: -2px;
          left: -0.2px;
          z-index: 5;
        }
      }
      .group-card--detail {
        max-height: calc(500px - 165px);
        height: calc(500px - 165px);
        overflow-y: auto;
        margin: 0px auto;
        padding: 1rem;
        border: 2px solid var(--v-primary-base) !important;
      }
    }
  }
}
.group-card {
  background: rgba(#c9e3f3, 0.7) !important;
  border-color: transparent !important;
  transition: border-color 0.2s;
  height: 150px;
  &.newgroup {
    background: rgba(#fff, 0.75) !important;
  }
  &:hover {
    border-color: var(--v-primary-base);
  }
  // group-card
  &--detail {
    position: absolute;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 0.5rem;
    max-height: 0;
    transition: max-height 0.3s ease-in-out, margin-bottom 0.1s 0.2s;
    width: calc(100% - 24px);
    box-sizing: border-box;
    background: $expandColor;
    height: 0;
  }
}

.expand-icon {
  svg {
    path {
      fill: var(--v-primary-base);
    }
  }
}

.groupDatatable {
  width: 100%;
  background: transparent !important;
  table td,
  .v-data-footer {
    background-color: var(--v-ems-blue-grey-base) !important;
    border-top: solid $expandColor !important;
  }

  thead {
    background: var(--v-secondary-base) !important;
  }
}

.removeTopLeftRadius {
  border-top-left-radius: 0;
}

.removeRightTopRadius {
  border-top-right-radius: 0;
}
</style>
