<template>
  <v-card
    elevation="0"
    tile
    color="transparent"
    height="465px"
    class="overflow-auto"
  >
    <v-card-subtitle
      class="fill-height white rounded d-flex align-center justify-center"
      v-if="deviceDataList.length === 0"
    >
      群組內尚無名單
    </v-card-subtitle>
    <div class="fill-height white rounded" v-else-if="type.id === 'CLASSMETER'">
      <vue-scroll>
        <template>
          <v-list two-line v-for="(item, index) in deviceDataList" :key="index">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.building.name || '' }}, {{ item.floor.name || '' }},
                  {{ item.classes.name || '' }}
                </v-list-item-title>
                <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="ems-blue-grey" dark elevation="0">
                  <v-icon @click="removeDevice(item.id)">
                    mdi-close-circle
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < deviceDataList.length - 1" :key="index" />
          </v-list>
        </template>
      </vue-scroll>
    </div>
    <div class="fill-height white rounded" v-else>
      <vue-scroll>
        <template>
          <v-list
            two-line
            v-for="(item, index) in deviceDataGroupByClasses"
            :key="index"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.building.name || '' }}, {{ item.floor.name || '' }},
                  {{ item.name || '' }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="ems-blue-grey" dark elevation="0">
                  <v-icon @click="removeClasses(item.id)">
                    mdi-close-circle
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < deviceDataList.length - 1" :key="index" />
          </v-list>
        </template>
      </vue-scroll>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'DeviceDataListCard',
  mixins: [],
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: Object,
      require: true
    }
  },
  data: function () {
    return {
      deviceDataList: this.value
    }
  },
  computed: {
    deviceDataGroupByClasses() {
      const groupByClasses = {}
      if (!this.deviceDataList) {
        return []
      }
      this.deviceDataList.forEach((device) => {
        const deviceItem =
          groupByClasses &&
          groupByClasses[device.classes.id] &&
          groupByClasses[device.classes.id].device
            ? groupByClasses[device.classes.id].device
            : []
        deviceItem.push(device)
        if (!groupByClasses[device.classes.id]) {
          groupByClasses[device.classes.id] = {}
        }
        groupByClasses[device.classes.id] = {
          id: device.classes.id,
          name: device.classes.name,
          building: device.building,
          floor: device.floor,
          device: deviceItem
        }
      })
      return groupByClasses
    }
  },
  watch: {
    value(val) {
      this.deviceDataList = val
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    removeDevice(id) {
      this.deviceDataList = this.deviceDataList.filter(
        (device) => device.id !== id
      )
      this.$emit('removeDevice', id)
    },
    removeClasses(classId) {
      this.deviceDataList
        .filter((device) => device.classes.id === classId)
        .forEach((device) => {
          this.$emit('removeDevice', device.id)
        })
      this.deviceDataList = this.deviceDataList.filter(
        (device) => device.classes.id !== classId
      )
    }
  }
}
</script>

<style></style>
