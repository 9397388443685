var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-3 py-8",attrs:{"elevation":"0","tile":"","color":"transparent","height":"100%","disabled":_vm.addGroupLoading}},[_c('v-card-title',{staticClass:"title font-weight-bold accent--text"},[_vm._v(" "+_vm._s(_vm.pool.id ? '編輯' : '新增')+"群組 ")]),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.saveGroup($event)}}},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-pencil-outline","placeholder":"群組名稱","rules":[
            function (v) { return !!v || '請填寫群組名稱'; },
            function (v) { return (v && v.length < 25) || '群組名稱請少於 25 字'; }
          ]},model:{value:(_vm.pool.name),callback:function ($$v) {_vm.$set(_vm.pool, "name", $$v)},expression:"pool.name"}})],1),_c('v-select',{attrs:{"items":_vm.groupOptions,"background-color":"white","solo":"","flat":"","item-text":"name","return-object":"","disabled":_vm.deviceDataList.length > 0},on:{"change":function($event){return _vm.clearSelected()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 選擇群組屬性"),_c('span',{staticClass:"error--text"},[_vm._v("*")])]},proxy:true},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
          var item = ref.item;
          var attrs = ref.attrs;
          var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}]),model:{value:(_vm.pool.type),callback:function ($$v) {_vm.$set(_vm.pool, "type", $$v)},expression:"pool.type"}}),_c('inputSelectMultiple',{attrs:{"items":_vm.buildings || [],"placeholder":"請選擇大樓","noDataText":"尚無可選擇的大樓","show-select-all":"","solo":"","flat":"","item-text":"name","item-value":"id"},model:{value:(_vm.selected.building),callback:function ($$v) {_vm.$set(_vm.selected, "building", $$v)},expression:"selected.building"}}),_c('inputSelectMultiple',{attrs:{"items":_vm.floors || [],"placeholder":"請選擇樓層","noDataText":"尚無可選擇的樓層","show-select-all":"","solo":"","flat":"","item-text":"name","item-value":"id"},model:{value:(_vm.selected.floor),callback:function ($$v) {_vm.$set(_vm.selected, "floor", $$v)},expression:"selected.floor"}}),_c('inputSelectMultiple',{attrs:{"items":_vm.classes || [],"placeholder":"請選擇教室","noDataText":"尚無可選擇的教室","show-select-all":"","solo":"","flat":"","item-text":"name","return-object":""},model:{value:(_vm.selected.class),callback:function ($$v) {_vm.$set(_vm.selected, "class", $$v)},expression:"selected.class"}}),(_vm.pool.type.id === 'CLASSMETER')?_c('inputSelectMultiple',{attrs:{"items":_vm.deviceOptions || [],"placeholder":"請選擇電表","noDataText":"尚無可選擇的電表","show-select-all":"","solo":"","flat":"","item-text":"name","return-object":""},model:{value:(_vm.selected.ac),callback:function ($$v) {_vm.$set(_vm.selected, "ac", $$v)},expression:"selected.ac"}}):_vm._e(),(
          _vm.pool.type &&
          ['AIRCONDITIONER', 'IR_AIRCONDITIONER', 'CARDREADER'].includes(
            _vm.pool.type.id
          )
        )?_c('div',[_c('v-icon',[_vm._v("mdi-alert")]),_c('span',{staticClass:"pl-1"},[_vm._v(" 屬性選擇為「控制冷氣」、「控制IR冷氣」或「控制既設冷氣」時，教室無法重複加入不同群組。 ")])],1):_vm._e(),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"ems-aquamarine","disabled":!_vm.addableRoom,"x-large":"","elevation":"0"},on:{"click":_vm.addtoPool}},[_vm._v(" 加入 "),_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.pool && _vm.pool.type)?_c('deviceDataListCard',{attrs:{"type":_vm.pool.type},on:{"removeDevice":_vm.removeDevice},model:{value:(_vm.deviceDataList),callback:function ($$v) {_vm.deviceDataList=$$v},expression:"deviceDataList"}}):_vm._e(),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","disabled":!_vm.addableGroup,"block":"","x-large":"","loading":_vm.addGroupLoading,"elevation":"0"},on:{"click":_vm.saveGroup}},[_vm._v(" "+_vm._s(_vm.pool.id ? '完成修改' : '新增群組')+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }