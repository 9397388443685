var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-overlay',{attrs:{"value":_vm.loadingGroup}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-sheet',{attrs:{"width":"1310px","color":"transparent mx-auto"}},[_c('div',{staticClass:"mb-1 text-right"},[_c('GroupAppliedHint')],1),_c('v-row',{staticClass:"group-container"},[_c('v-col',{staticClass:"group",attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-card',{staticClass:"\n            group-card\n            newgroup\n            pa-2\n            d-flex\n            flex-column\n            align-center\n            justify-center\n            rounded-lg\n          ",attrs:{"ripple":"","outlined":"","width":"100%"},on:{"click":function($event){return _vm.editGroup(null)}}},[_c('v-icon',{attrs:{"color":"secondary","x-large":""}},[_vm._v(" mdi-plus-thick")]),_c('div',{staticClass:"ems-blue-grey-2--text"},[_vm._v("新增群組")])],1)],1),_vm._l((_vm.groups),function(item,groupIndex){return _c('v-col',{key:item.id,class:['group', { 'is-expanded': _vm.pool.id === item.id }],attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-sheet',{staticClass:"py-2 px-3 group-card frosted-glass rounded-lg",attrs:{"width":"100%"},on:{"click":function($event){return _vm.showGroup(item)}}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center px-1 pt-1 pb-1"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold accent--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('div',[(
                  !item.isSetToSchedule &&
                  !item.isSetToOffloading &&
                  !item.isSetToOpenAdr
                )?_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","icon":"","color":"ems-blue-grey"},on:{"click":function($event){return _vm.deleteGroup(item, groupIndex)}}},[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1):_vm._e()],1)]),_c('v-card-text',{staticClass:"accent--text px-0"},[_c('div',{staticClass:"mb-1"},[_c('GroupApply',{attrs:{"group":item}})],1),(item.type === 'METER')?_c('div',{staticClass:"body-1 d-flex align-center"},[_c('v-img',{attrs:{"max-height":"32","max-width":"32","src":require("@/assets/image/icon_Meter.svg")}}),_vm._v(" 數量："+_vm._s(item.countMap.CLASSMETER || 0)+" ")],1):_vm._e(),(item.type === 'IR_AIR_CON')?_c('div',{staticClass:"body-1 d-flex align-center"},[_c('v-img',{staticClass:"mx-1",attrs:{"max-height":"32","max-width":"24","contain":"","src":require("@/assets/image/acControl/ir.png")}}),_vm._v(" 數量："+_vm._s(item.countMap.IR_AIRCONDITIONER || 0)+" ")],1):(item.type === 'AIR_CON')?_c('div',{staticClass:"body-1 d-flex align-center"},[_c('v-img',{attrs:{"max-height":"32","max-width":"32","src":require("@/assets/image/icon_AC.svg")}}),_vm._v(" 數量："+_vm._s(item.countMap.AIRCONDITIONER || 0)+" ")],1):(item.type === 'CARD_READER')?_c('div',{staticClass:"body-1 d-flex align-center"},[_vm._v(" 既設冷氣 ")]):_vm._e(),(item.type === 'AIR_CON')?_c('div',{staticClass:"body-1 d-flex mt-1"},[_c('v-sheet',{staticClass:"text-center",attrs:{"width":"32","height":"32","color":"transparent"}},[_c('v-icon',{attrs:{"x-small":"","color":['PARTIALLY_ON', 'ON'].includes(
                      item.statusMap.AIRCONDITIONER
                    )
                      ? 'success'
                      : 'error'}},[_vm._v(" mdi-checkbox-blank-circle")])],1),_vm._v(" 狀態：冷氣"+_vm._s(_vm.state[item.statusMap.AIRCONDITIONER])+" ")],1):_vm._e(),(item.type === 'CARD_READER')?_c('div',{staticClass:"body-1 d-flex mt-1"},[_c('v-sheet',{staticClass:"text-center",attrs:{"width":"32","height":"32","color":"transparent"}},[_c('v-icon',{attrs:{"x-small":"","color":['PARTIALLY_ON', 'ON'].includes(item.statusMap.CARDREADER)
                      ? 'success'
                      : 'error'}},[_vm._v(" mdi-checkbox-blank-circle")])],1),_vm._v(" 狀態：電驛"+_vm._s(_vm.cardReaderState[item.statusMap.CARDREADER])+" ")],1):_vm._e()])],1),_c('div',{staticClass:"connectExpand"}),_c('div',{staticClass:"group-card--detail accent--text",class:_vm.removeBorderRadius(groupIndex)},[_c('div',{staticClass:"body-1 mb-3 d-flex justify-space-between"},[(
                !item.isSetToSchedule &&
                !item.isSetToOffloading &&
                !item.isSetToOpenAdr
              )?_c('v-btn',{attrs:{"elevation":"0","color":"primary","large":""},on:{"click":function($event){return _vm.editGroup(item)}}},[_vm._v(" 編輯群組")]):_vm._e(),(
                item.isSetToSchedule ||
                item.isSetToOffloading ||
                item.isSetToOpenAdr
              )?_c('span',{staticClass:"caption grey--text"},[_vm._v("已使用於超約卸載或排程或ADR方案，無法修改。")]):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"ems-blue-grey"},on:{"click":function($event){return _vm.showGroup(undefined)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-sheet',{staticClass:"d-flex justify-center",class:{ 'align-center': _vm.pool.loading },staticStyle:{"overflow-y":"auto"},attrs:{"width":"100%","height":"calc(100% - 60px)","color":"transparent"}},[(_vm.pool.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"ems-bluegrey"}}):_c('v-data-table',{staticClass:"groupDatatable elevation-0 mb-3",attrs:{"headers":_vm.headers,"items":_vm.pool.deviceList,"items-per-page":5,"loading-text":"Loading...","no-data-text":"查無資料","footer-props":{
                'items-per-page-text': '每頁'
              }}})],1)],1)],1)})],2)],1),_c('v-navigation-drawer',{staticClass:"frosted-glass-25",attrs:{"fixed":"","temporary":"","right":"","color":"rgba(256, 256, 256, 0.2)","overlay-color":"rgba(256, 256, 256, 0.2)","width":"70%"},model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('editGroupCard',{on:{"save":function($event){return _vm.saveGroup($event)}},model:{value:(_vm.pool),callback:function ($$v) {_vm.pool=$$v},expression:"pool"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }