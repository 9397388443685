<template>
  <v-card
    elevation="0"
    tile
    color="transparent"
    height="100%"
    class="px-3 py-8"
    :disabled="addGroupLoading"
  >
    <v-card-title class="title font-weight-bold accent--text">
      {{ pool.id ? '編輯' : '新增' }}群組
    </v-card-title>
    <v-row class="mx-0">
      <v-col cols="6">
        <v-form ref="form" lazy-validation @submit.prevent="saveGroup">
          <v-text-field
            outlined
            append-icon="mdi-pencil-outline"
            placeholder="群組名稱"
            :rules="[
              (v) => !!v || '請填寫群組名稱',
              (v) => (v && v.length < 25) || '群組名稱請少於 25 字'
            ]"
            v-model="pool.name"
          />
        </v-form>
        <v-select
          v-model="pool.type"
          :items="groupOptions"
          background-color="white"
          solo
          flat
          item-text="name"
          return-object
          :disabled="deviceDataList.length > 0"
          @change="clearSelected()"
        >
          <template v-slot:label>
            選擇群組屬性<span class="error--text">*</span>
          </template>
          <template slot="selection" slot-scope="{ item }">
            <div class="d-flex align-center">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-content>
                {{ item.name }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <inputSelectMultiple
          v-model="selected.building"
          :items="buildings || []"
          placeholder="請選擇大樓"
          noDataText="尚無可選擇的大樓"
          show-select-all
          solo
          flat
          item-text="name"
          item-value="id"
        />
        <inputSelectMultiple
          v-model="selected.floor"
          :items="floors || []"
          placeholder="請選擇樓層"
          noDataText="尚無可選擇的樓層"
          show-select-all
          solo
          flat
          item-text="name"
          item-value="id"
        />
        <inputSelectMultiple
          v-model="selected.class"
          :items="classes || []"
          placeholder="請選擇教室"
          noDataText="尚無可選擇的教室"
          show-select-all
          solo
          flat
          item-text="name"
          return-object
        />

        <inputSelectMultiple
          v-if="pool.type.id === 'CLASSMETER'"
          v-model="selected.ac"
          :items="deviceOptions || []"
          placeholder="請選擇電表"
          noDataText="尚無可選擇的電表"
          show-select-all
          solo
          flat
          item-text="name"
          return-object
        />
        <div
          v-if="
            pool.type &&
            ['AIRCONDITIONER', 'IR_AIRCONDITIONER', 'CARDREADER'].includes(
              pool.type.id
            )
          "
        >
          <v-icon>mdi-alert</v-icon>
          <span class="pl-1">
            屬性選擇為「控制冷氣」、「控制IR冷氣」或「控制既設冷氣」時，教室無法重複加入不同群組。
          </span>
        </div>
        <div class="text-right">
          <v-btn
            @click="addtoPool"
            color="ems-aquamarine"
            :disabled="!addableRoom"
            x-large
            elevation="0"
            class="font-weight-bold"
          >
            加入
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="6">
        <deviceDataListCard
          v-if="pool && pool.type"
          v-model="deviceDataList"
          :type="pool.type"
          @removeDevice="removeDevice"
        />
        <v-btn
          class="mt-3"
          @click="saveGroup"
          color="primary"
          :disabled="!addableGroup"
          block
          x-large
          :loading="addGroupLoading"
          elevation="0"
        >
          {{ pool.id ? '完成修改' : '新增群組' }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Group from '@/api/ems/Group'
import deviceDataListCard from './deviceDataListCard'
import inputSelectMultiple from '@/components/inputSelectMultiple'

export default {
  name: undefined,
  mixins: [],
  components: {
    deviceDataListCard,
    inputSelectMultiple
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: '',
          name: '',
          type: '',
          deviceDataList: []
        }
      }
    }
  },
  data: function () {
    return {
      pool: this.value,
      selected: {
        building: null,
        floor: null,
        class: null,
        type: null,
        ac: []
      },
      groups: [],
      addGroupLoading: false,
      deviceIdList: [],
      usedDevice: []
    }
  },
  computed: {
    ...mapGetters({
      buildingOrigin: 'building/buildings',
      allfloors: 'building/floorList',
      myGroups: 'groups/groups',
      groupTypes: 'groups/typeOptions'
    }),
    tagId() {
      return this.$route.params.tagId
    },
    addableGroup() {
      return this.deviceDataList.length > 0 && this.pool.name
    },
    addableRoom() {
      return this.selected.ac.length > 0
    },
    deviceOptions() {
      const selectedType = this.pool.type
      if (!selectedType) return []

      return this.devices.filter(({ type }) => type === selectedType.id)
    },
    hideDeviceId() {
      if (
        this.pool.type &&
        ['AIRCONDITIONER', 'IR_AIRCONDITIONER', 'CARDREADER'].includes(
          this.pool.type.id
        )
      ) {
        // 已被選取或其他群組已使用的device
        const hideDeviceId = this.deviceDataList
          .map((d) => d.id)
          .concat(this.usedDevice.map((d) => d.deviceId))
        return hideDeviceId
      }
      return this.deviceDataList.map((d) => d.id)
    },
    buildings() {
      if (!this.buildingOrigin) {
        return []
      }

      const building = JSON.parse(JSON.stringify(this.buildingOrigin)).map(
        (b) => {
          // b.floorList = b.floorList.map((f) => {
          //   f.deviceList = f.deviceList.filter(
          //     (d) => d.type === this.pool.type.id
          //   )
          //   f.classList = f.classList.map((c) => {
          //     c.deviceList = c.deviceList.filter(
          //       (classDevice) => classDevice.type === this.pool.type.id
          //     )
          //     return c
          //   })
          //   return f
          // })

          b.classesList = (
            this.pool.type.id === 'CARDREADER'
              ? b.classesList.filter(({ type }) => type === 'EXISTING_AIR_CON')
              : b.classesList
          ).map((c) => {
            c.deviceList = c.deviceList.filter(
              (d) => d.type === this.pool.type.id
            )
            return c
          })

          b.deviceList = b.classesList.reduce((acc, cur) => {
            acc = acc.concat(cur.deviceList)
            return acc
          }, [])

          b.floorList = b.floorList
            .filter(({ id }) =>
              b.classesList.find(({ floorId }) => id === floorId)
            )
            .map((f) => {
              f.deviceList = f.deviceList.filter(
                (d) => d.type === this.pool.type.id
              )
              f.classList = f.classList.map((c) => {
                c.deviceList = c.deviceList.filter(
                  (classDevice) => classDevice.type === this.pool.type.id
                )
                return c
              })
              return f
            })

          return b
        }
      )

      return building.filter((building) => {
        let hasDevice = false

        const allDeviceID = building.deviceList.map((d) => d.id)

        // 確認還有未被選取使用的device
        allDeviceID.forEach((deviceId) => {
          if (!this.hideDeviceId.includes(deviceId)) {
            hasDevice = true
          }
        })
        return hasDevice
      })
    },
    floors() {
      if (!this.selected.building) {
        return []
      }
      const selectBuildingData = this.buildings.filter((b) =>
        this.selected.building.includes(b.id)
      )
      const floors = []
      selectBuildingData.forEach((building) => {
        building.floorList.forEach((floor) => {
          let hasDevice = false

          // 確認還有未被選取使用的device
          floor.deviceList.forEach((d) => {
            if (!this.hideDeviceId.includes(d.id)) {
              hasDevice = true
            }
          })
          if (hasDevice) {
            floors.push(floor)
          }
        })
      })
      return floors
    },
    classes() {
      if (!this.selected.floor) {
        return []
      }
      const selectFloorData = this.floors.filter((f) =>
        this.selected.floor.includes(f.id)
      )
      const classes = []
      selectFloorData.forEach((floor) => {
        this.buildings
          .reduce((acc, curr) => {
            return (acc = acc.concat(curr.classesList))
          }, [])
          .filter(({ floorId }) => floorId === floor.id)
          .forEach((c) => {
            let hasDevice = false

            // 確認還有未被選取使用的device
            c.deviceList.forEach((d) => {
              if (!this.hideDeviceId.includes(d.id)) {
                hasDevice = true
              }
            })
            if (hasDevice) {
              classes.push(c)
            }
          })
      })
      return classes
    },
    devices() {
      if (!this.selected.class) {
        return []
      }
      const allDevices = []
      this.selected.class.forEach((_class) => {
        _class.deviceList.forEach((_device) => {
          allDevices.push(_device)
        })
      })
      return allDevices
    },
    deviceDataList() {
      const deviceDataList = this.deviceIdList.map((deviceId) => {
        let deviceData = {}
        if (!this.buildingOrigin) {
          return deviceData
        }
        this.buildingOrigin.forEach((building) => {
          building.floorList.forEach((floor) => {
            floor.classList.forEach((_class) => {
              _class.deviceList.forEach((device) => {
                if (deviceId === device.id) {
                  deviceData = {
                    id: device.id,
                    name: device.name,
                    seq: device.seq,
                    type: device.type,
                    building: {
                      id: building.id,
                      name: building.name,
                      seq: building.seq
                    },
                    classes: {
                      id: _class.id,
                      name: _class.name,
                      seq: _class.seq
                    },
                    floor: {
                      id: floor.id,
                      name: floor.name,
                      seq: floor.seq
                    }
                  }
                }
              })
            })
          })
        })
        return deviceData
      })

      return deviceDataList
    },
    commitData() {
      const commitData = {
        deviceIdList: this.deviceIdList,
        name: this.pool.name,
        seq: this.pool.seq,
        type: this.getGroupType(this.pool.type.id)
      }
      if (this.pool.id) {
        commitData.id = this.pool.id
      } else {
        commitData.schoolId = this.tagId
        commitData.seq = 0
      }
      return commitData
    },
    hasIR() {
      return !!this.buildingOrigin?.find(({ deviceList }) =>
        deviceList?.find(({ type }) => type === 'IR_AIRCONDITIONER')
      )
    },
    hasEstablishedAc() {
      return !!this.buildingOrigin?.find(({ classesList }) =>
        classesList?.find(({ type }) => type === 'EXISTING_AIR_CON')
      )
    },
    groupOptions() {
      let groupTypesOrigin = this.groupTypes
      if (!this.hasIR)
        groupTypesOrigin = groupTypesOrigin.filter(({ id }) => {
          return id !== 'IR_AIRCONDITIONER'
        })
      if (!this.hasEstablishedAc)
        groupTypesOrigin = groupTypesOrigin.filter(({ id }) => {
          return id !== 'CARDREADER'
        })
      return groupTypesOrigin
    }
  },
  watch: {
    value(val) {
      this.pool = val
      this.addGroupLoading = false
      this.selected.type = val.type
      this.initSelectedDevices()
      this.$refs.form.resetValidation()
    },
    'selected.class'(val) {
      if (
        val &&
        this.pool.type &&
        ['AIRCONDITIONER', 'IR_AIRCONDITIONER', 'CARDREADER'].includes(
          this.pool.type.id
        )
      ) {
        this.selected.ac = this.devices.filter(({ type, id }) => {
          return (
            type === this.pool.type.id &&
            this.usedDevice.filter(({ deviceId }) => deviceId === id).length ===
              0
          )
        })
      }
    }
  },
  created: function () {},
  mounted: function () {
    this.getBuildings()
    this.getGroups(this.tagId)
    this.getUsedDevice()
    this.groups = this.myGroups
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getAllBuildings: 'building/getAllBuildings',
      getGroups: 'groups/getGroups'
    }),
    initSelectedDevices() {
      this.getUsedDevice()
      this.deviceIdList = this.value.deviceList.map((item) => {
        return item.id
      })
    },
    async getBuildings() {
      if (!this.buildings || this.buildings.length === 0) {
        this.getAllBuildings(this.tagId)
      }
    },
    addtoPool() {
      if (this.selected.ac.length > 0) {
        this.selected.ac.forEach((selectedItem) => {
          if (this.deviceIdList.includes(selectedItem.id)) {
            return
          }
          this.deviceIdList.push(selectedItem.id)
        })
        this.clearSelected()
      }
    },
    removeDevice(id) {
      this.deviceIdList = this.deviceIdList.filter(
        (deviceId) => deviceId !== id
      )
    },
    saveGroup() {
      const validate = this.$refs.form.validate()
      if (!validate || !this.addableGroup) return
      this.addGroupLoading = true
      this.$emit('save', this.commitData)
    },
    getUsedDevice() {
      Group.getUsedDevice(this.tagId).then((response) => {
        this.usedDevice = response.data.filter(
          (device) => device.groupId !== this.value.id
        )
      })
    },
    clearSelected() {
      // 清除已選項目
      this.selected.ac = []
      this.selected.class = []
      this.selected.floor = []
      this.selected.building = []
    },
    getGroupType(type) {
      switch (type) {
        case 'CLASSMETER':
          return 'METER'
        case 'AIRCONDITIONER':
          return 'AIR_CON'
        case 'IR_AIRCONDITIONER':
          return 'IR_AIR_CON'
        case 'CARDREADER':
          return 'CARD_READER'
        default:
          return 'NULL'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-select__slot .v-select__selections span {
    color: #000000de !important;
    font-weight: normal;
  }
}
</style>
