<template>
  <v-select
    v-bind="$attrs"
    v-model="selected"
    multiple
    @click="onClick"
    :items="items"
    :rules="[...rules]"
    :item-text="itemText"
    :item-value="itemValue"
  >
    <template v-slot:label>
      {{ placeholder }}<span v-if="required" class="error--text">*</span>
    </template>
    <template v-slot:prepend-item>
      <v-list-item
        ripple
        @click="toggleAll"
        :color="color"
        :active-class="`${color}--text`"
      >
        <v-list-item-action>
          <v-icon :color="likesAll ? color : ''">
            {{
              likesAll ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title :class="likesAll ? `${color}--text` : null">
            全選
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>
    </template>
    <template v-slot:selection="{ item }">
      <span v-if="showItem" :class="`${color}--text`">{{
        item[itemText]
      }}</span>
    </template>
  </v-select>
</template>

<script>
export default {
  inheritAttrs: false,
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String
    },
    itemValue: {
      default: 'value'
    },
    itemText: {
      default: 'text'
    },
    required: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      selected: [],
      showItem: true
    }
  },
  computed: {
    likesAll() {
      return this.selected && this.selected.length === this.items.length
    }
  },
  watch: {
    value: function (val) {
      this.selected = val
    },
    selected: function (val) {
      this.commit()
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.selected = []
        } else {
          this.selected = this.items.map((item) => {
            return item[this.itemValue] || item
          })
        }
      })
    },
    commit() {
      this.$emit('input', this.selected)
    },
    onClick() {
      this.showItem = false
      this.$nextTick(() => {
        this.showItem = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-select__selections {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline;
    color: var(--v-accent-base);
    font-weight: 700;
    span + span:before {
      content: ', ';
    }
    > input {
      width: auto;
    }
  }
}
</style>
